import { BASE_URL } from '../config';
import { refreshAccessToken } from './apiUtils';
import { isAccessTokenExpired } from './jwt';

let accessToken: string;
let rootLogout: () => Promise<void>;
function setAccessToken(token: string): void {
  accessToken = token;
}

export function setRootLogout(logout: () => Promise<void>): void {
  rootLogout = logout;
}
export async function apiQuery<T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  init: RequestInit & { resetAccessToken?: boolean } = {},
  contentType?: string | null | undefined
): Promise<T> {
  try {
    if (contentType !== null && !contentType) {
      contentType = 'application/json';
    }
    let requestUrl = url;
    if (url.startsWith('/')) {
      requestUrl = `${BASE_URL}${url}`;
    }
    if (isAccessTokenExpired(accessToken)) {
      // Refresh token
      try {
        const token = await refreshAccessToken();
        setAccessToken(token);
        if (!token) {
          throw new Error('401 Authentication Error');
        }
      } catch (e) {
        console.error(e);
        throw new Error('401 Authentication Error');
      }
    }
    // TODO: Add expiry check for access token and use refresh token to refresh inline
    const defaultInit: RequestInit = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'omit',
    };
    if (contentType !== null) {
      // @ts-ignore
      defaultInit.headers['Content-Type'] = contentType;
    }

    const response = await fetch(requestUrl, { ...defaultInit, ...init });
    let json;
    const count = response.headers.get('X-Total-Count');

    if (response.headers.get('content-type') === 'application/pdf') {
      return response as unknown as T;
    }

    try {
      json = await response.json();

      // This is for user impersonation
      if (init.resetAccessToken) {
        setAccessToken(await refreshAccessToken());
      }
    } catch (e) {
      console.error(`Invalid JSON`, e);
    }

    if (response.ok) {
      if (count) {
        return { data: json, totalCount: count } as T;
      }
      return json;
    }
    throw new Error(JSON.stringify(json));
  } catch (e) {
    const lowerMessage = e.message.toLowerCase();
    if (
      lowerMessage.includes('401') ||
      lowerMessage.includes('forbidden') ||
      lowerMessage.includes('invalid token') ||
      lowerMessage.includes('token expired') ||
      lowerMessage.includes('token has been revoked') ||
      lowerMessage.includes('invalid jwt') ||
      lowerMessage.includes('unauthorized') ||
      lowerMessage.includes('missing token')
    ) {
      rootLogout?.();
    }
    throw e.message;
  }
}

export function accessTokenSet(): boolean {
  const cookies = document.cookie.split('; ');

  // Find the refreshExpire cookie
  const refreshTokenCookie = cookies.find((cookie) => cookie.startsWith('refreshExpire='));

  if (!refreshTokenCookie) {
    return false;
  }

  // Extract the expiry time from the cookie
  const expiryTime = refreshTokenCookie.split('=')[1];

  // Compare the expiry time to the current time
  return Number(expiryTime) > Date.now();
}
