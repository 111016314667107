import {
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  Paper,
  PaperProps,
  Stack,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { forEach } from 'lodash';
import React, { SyntheticEvent, memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModifiedSearchResult, SearchResults, SearchType } from '../../../@types/api/search';
import Iconify from '../../../components/iconify';
import SearchNotFound from '../../../components/search-not-found';
import { ACCOUNTS_PATH, DEALS_PATH, MEETINGS_PATH } from '../../../config';
import { fDateFull } from '../../../utils/formatTime';
import { getSearchItemIcon } from './DashboardNavUtils';
import { useGetSearchResult } from 'src/hooks/useQueries';

const StyledPaper = styled((props: PaperProps) => <Paper {...props} />)(({ theme }) => ({}));

const style = {
  position: 'absolute' as 'absolute',
  top: '200px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  maxHeight: 400,
  width: 'fit-content',
  outline: 'none',
  bgcolor: 'background.paper',
  borderRadius: 2,
};

function Searchbar() {
  // const { user } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data: searchResults, isLoading, isFetching } = useGetSearchResult(searchQuery);

  const [availableOptions, setAvailableOptions] = useState<ModifiedSearchResult[]>([]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenSearchResults = () => {
    setOpen(true);
  };

  const handleCloseSearchResults = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    handleCloseSearchResults();
  }, [pathname, handleCloseSearchResults]);

  useEffect(() => {
    const allItems: ModifiedSearchResult[] = [];
    const { ...availableFields } = searchResults ?? ({} as SearchResults);
    forEach(availableFields, (items, key) => {
      allItems.push(...items.map((item) => ({ ...item, type: key as SearchType })));
    });
    setAvailableOptions(allItems);
  }, [searchResults]);

  const handleInputChange = (event: SyntheticEvent, value: string, reason: string) => {
    if (event) {
      handleOpenSearchResults();
      queryClient.invalidateQueries(['search', value]);
      setSearchQuery(value);
      if (!value) return handleCloseSearchResults();
    }
  };

  const handleNavigate = (option: ModifiedSearchResult) => {
    if (option.type === SearchType.DEALS) {
      handleCloseModal();
      return navigate(`/${DEALS_PATH}/${option.id}`);
    }
    if (option.type === SearchType.MEETINGS) {
      handleCloseModal();
      return navigate(`/${DEALS_PATH}/${option.dealId}/${MEETINGS_PATH}/${option.id}`);
    }
    if (option.type === SearchType.COMPANIES) {
      handleCloseModal();
      return navigate(`/${ACCOUNTS_PATH}/${option.id}/${DEALS_PATH}`);
    }
  };

  return (
    <>
      <Box sx={{ position: 'relative', padding: '0 24px 8px' }}>
        <OutlinedInput
          id="search"
          placeholder="Search"
          value={''}
          fullWidth
          onClick={() => setOpenModal(true)}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
          sx={{
            caretColor: 'transparent',
            color: theme.palette.common.white,
            height: '38px',
            borderRadius: '6px',
            '&.MuiAutocomplete-root': {
              padding: '50px',
              flexWrap: 'no-wrap',
            },
            '&.MuiOutlinedInput-root': {
              padding: '0',
              paddingLeft: '10px',
            },
            '&:hover fieldset': {
              borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
            },
            '&.Mui-focused fieldset': {
              borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
            },
          }}
        />
      </Box>
      <Modal
        open={openModal}
        onClick={(event: any) => event.stopPropagation()}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: '5000' }}
      >
        <Box sx={style}>
          <Autocomplete
            open={open}
            onClose={handleCloseSearchResults}
            disablePortal
            disableClearable
            fullWidth
            popupIcon={null}
            PaperComponent={StyledPaper}
            onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                handleNavigate(value);
              }
            }}
            onInputChange={(event, value, reason) => handleInputChange(event, value, reason)}
            noOptionsText={
              <SearchNotFound
                isLoading={isLoading || isFetching}
                query={searchQuery}
                sx={{
                  py: 5,
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.grey[900],
                }}
              />
            }
            options={availableOptions.sort((a, b) => -b.description.localeCompare(a.description))}
            getOptionLabel={(option: ModifiedSearchResult) => option?.description || ''}
            filterOptions={createFilterOptions({
              matchFrom: 'any',
              stringify: (option) => option.description,
            })}
            sx={{
              '& + .MuiAutocomplete-popper .MuiPaper-root': {
                borderTopRightRadius: open ? 0 : 16,
                borderTopLeftRadius: open ? 0 : 16,
                '& .MuiAutocomplete-listbox': {
                  padding: '0',
                },
              },
            }}
            renderInput={(params) => (
              <OutlinedInput
                {...params.InputProps}
                autoFocus
                inputProps={params.inputProps}
                placeholder="Search for accounts, deals or meetings…"
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
                sx={{
                  backgroundColor: theme.palette.common.white,
                  borderColor: theme.palette.common.white,
                  borderRadius: 2,
                  borderBottomRightRadius: open ? 0 : 16,
                  borderBottomLeftRadius: open ? 0 : 16,
                  '&.MuiAutocomplete-root': {
                    padding: '50px',
                    flexWrap: 'no-wrap',
                  },
                  '&.MuiOutlinedInput-root': {
                    padding: '16px 24px!important',
                    paddingLeft: '10px',
                    '.MuiAutocomplete-input': {
                      padding: '0',
                    },
                  },
                  '.MuiOutlinedInput-input': {
                    padding: '0!important',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none!important',
                  },
                  '&:fieldset': {
                    border: 'none!important',
                  },
                  '&:hover fieldset': {
                    border: 'none!important',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none!important',
                  },
                }}
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const { description } = option;

              const partsTitle = parse(
                description,
                match(description, inputValue, { insideWords: true })
              );

              return (
                <React.Fragment key={option.id}>
                  <Divider />
                  <Box
                    component="li"
                    {...props}
                    id={option.id}
                    onClick={() => handleNavigate(option)}
                    style={{ padding: '16px 24px', margin: '0', alignItems: 'start' }}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.grey[100],
                      },
                    }}
                  >
                    <>
                      {getSearchItemIcon(option, theme)}
                      <Stack>
                        <>
                          <Box style={{ display: 'block' }}>
                            {partsTitle.map((part, index) => (
                              <Box
                                key={option.id + index}
                                component="span"
                                sx={{
                                  variant: 'subtitle2',
                                  color: part.highlight
                                    ? theme.palette.primary.main
                                    : theme.palette.common.black,
                                }}
                              >
                                {part.text}
                              </Box>
                            ))}
                          </Box>
                          {option.meetingDate && fDateFull(option.meetingDate)}
                        </>
                      </Stack>
                    </>
                  </Box>
                </React.Fragment>
              );
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

export default memo(Searchbar);
