import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import GongComponent from '../react-components/GongComponent';

export type GongNodeType = {
  attrs: GongAttributesType;
};

export type GongAttributesType = {
  id: string;
  name: string;
  callId: string;
  start: Date;
  end: Date;
};

export const Gong = Node.create({
  name: 'contextGong',

  addOptions() {
    return {
      HTMLAttributes: {
        className: 'gong',
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  parseHTML() {
    return [
      {
        tag: 'context-gong',
      },
    ];
  },

  addAttributes() {
    return {
      id: {
        default: '',
      },
      name: {
        default: '',
      },
      callId: {
        default: '',
      },
      start: {
        default: '',
      },
      end: {
        default: '',
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isGong = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isGong = true;
              tr.insertText('', pos, pos + node.nodeSize);

              return false;
            }
          });

          return isGong;
        }),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['context-gong', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(GongComponent);
  },
});
