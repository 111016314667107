export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const CONTEXT_AUTH = {
  auth: '/auth',
  login: '/login',
};

export const CONTEXT_HOME = '';
export const CONTEXT_SALESFORCE_LOADING = '/salesforce-loading';
export const CONTEXT_SALESFORCE_LOGIN = '/salesforce';
