import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { LOCAL_STORAGE, SALESFORCE_PATH } from '../config';
import { useGetSalesforceToken } from '../hooks/useQueries';
import LoadingScreen from 'src/components/loading-screen';

type SalesforceGuardProps = {
  children: ReactNode;
};

export default function SalesforceGuard({ children }: SalesforceGuardProps) {
  // TODO use boolean not access token
  const { data: salesforceToken, isLoading } = useGetSalesforceToken();

  const isSkipSalesforce = localStorage.getItem(LOCAL_STORAGE.SALESFORCE_SKIP);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!salesforceToken?.accessToken && !isSkipSalesforce) {
    return <Navigate to={`/${SALESFORCE_PATH}`} replace={true} />;
  }

  return <>{children}</>;
}
