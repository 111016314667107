import { styled } from '@mui/material/styles';

import Iconify from 'src/components/iconify';

export const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 0,
  flexShrink: 0,
  marginRight: theme.spacing(0),
}));

const html = String.raw;
export const NOTES_TEMPLATE = html``;
