import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ACCOUNTS_PATH, DEALS_PATH, NOTES_PATH, TEMPLATES_PATH } from '../../../config';
import MessagePage from './MessagePage';
import { NotesSource } from 'src/@types/api/notes';
import { useNotesContext } from 'src/hooks/useNotesContext';

const NoteClosedMessagePage = ({ reason, custom }: { reason: string; custom?: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { refresh } = useNotesContext();
  const { deal_id, meeting_id, account_id } = useParams();
  const pageType = location.pathname[1];

  const path = location.pathname.split('/').slice(0, -1).join('/');
  const title = custom
    ? custom
    : reason === 'Note has been deleted'
      ? 'This note has been deleted'
      : 'Something went wrong';

  const pageData = useMemo(() => {
    if (pageType === TEMPLATES_PATH)
      return {
        buttonText: 'Go To Templates',
        fn: () => queryClient.invalidateQueries(['noteTemplates']),
      };

    if (meeting_id)
      return {
        buttonText: 'Go To Meetings',
        fn: () => queryClient.invalidateQueries(['dealMeetings', deal_id]),
      };
    if (pageType === DEALS_PATH)
      return {
        buttonText: 'Go To Deal Notes',
        fn: () => queryClient.invalidateQueries(['deal', deal_id]),
      };
    if (pageType === NOTES_PATH)
      return {
        buttonText: 'Go To All Notes',
        fn: () => refresh(NotesSource.User),
      };

    if (pageType === ACCOUNTS_PATH)
      return {
        buttonText: 'Go To Account Notes',
        fn: () => queryClient.invalidateQueries(['company', account_id]),
      };

    return {
      buttonText: 'Go To Notes',
      fn: () => {},
    };
  }, [deal_id, pageType, queryClient, refresh, meeting_id, account_id]);

  const handleClick = async () => {
    navigate(path, { replace: true });
    pageData.fn();
  };

  return (
    <MessagePage
      title={title}
      buttonText={pageData.buttonText}
      buttonAction={() => handleClick()}
      sx={{ height: '100%' }}
    />
  );
};

export default NoteClosedMessagePage;
