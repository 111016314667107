import { Divider, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from '../../../assets/icons/navIcons/logout.svg';
import { ReactComponent as TemplateIcon } from '../../../assets/icons/navIcons/template.svg';
import { useAuthContext } from '../../../auth/useAuthContext';
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { LOCAL_STORAGE, SESSION_STORAGE, TEMPLATES_PATH } from '../../../config';
import { useActiveSectionContext } from '../../../hooks/useActiveNavSection';
import { CONTEXT_AUTH, CONTEXT_SALESFORCE_LOGIN } from '../../../routes/paths';
import { NavSection } from '../nav/NavVertical';
import SwitchUserPopover from './SwitchUserPopover';

export default function UserMenuPopover() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, logout } = useAuthContext();
  const { setActiveSection } = useActiveSectionContext();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const isAdminUser = user?.role === 'admin';

  const handleConnectSalesforce = () => {
    localStorage.removeItem('isSkipSalesforce');
    navigate(CONTEXT_SALESFORCE_LOGIN);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(CONTEXT_AUTH.login, { replace: true });
      enqueueSnackbar('Signed out!', { variant: 'success' });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to sign out!', { variant: 'error' });
    }
  };

  const handleTemplate = () => {
    handleClosePopover();
    sessionStorage.setItem(SESSION_STORAGE.SELECTED_SECTION, NavSection.TEMPLATES);
    setActiveSection(NavSection.TEMPLATES);
    localStorage.setItem(LOCAL_STORAGE.PREV_PAGE_TEMPLATE, window.location.pathname);
    navigate(`/${TEMPLATES_PATH}`);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <CustomAvatar src={user?.image} name={user?.email} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        disabledArrow
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ boxShadow: theme.customShadows.dropdownDark }}
      >
        {isAdminUser && (
          <>
            <SwitchUserPopover /> <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}
        <MenuItem onClick={handleConnectSalesforce}>Connect to Salesforce</MenuItem>
        <MenuItem onClick={handleTemplate}>
          <TemplateIcon style={{ marginRight: '8px' }} />
          Templates
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon style={{ marginRight: '8px' }} />
          Sign Out
        </MenuItem>
      </MenuPopover>
    </>
  );
}
