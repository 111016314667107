import { Box, Tooltip } from '@mui/material';
import { Editor } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import { ReactComponent as GongIcon } from '../../../assets/icons/tiptapIcons/gongNodeIcon.svg';
import GongModal from '../../gong/GongModal';
import { GongAttributesType, GongNodeType } from '../extensions/GongNode';

type GongComponentProps = {
  node: GongNodeType;
  updateAttributes: (attrs: GongAttributesType) => void;
  selected: boolean;
  editor: Editor | null;
};

const GongComponent = ({ node }: GongComponentProps) => {
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <NodeViewWrapper as={'span'} className={'gong'}>
        <Tooltip title="View Gong Call">
          <Box component={'span'} onClick={handleOpenModal} sx={{ cursor: 'pointer' }}>
            <GongIcon style={{ verticalAlign: 'middle', marginRight: '2px' }} />
            {node.attrs.name}
          </Box>
        </Tooltip>
      </NodeViewWrapper>
      <GongModal
        open={open}
        handleCloseModal={handleCloseModal}
        name={node.attrs.name ?? ''}
        callId={node.attrs.callId}
        start={node.attrs.start}
        end={node.attrs.end}
      />
    </>
  );
};

export default GongComponent;
