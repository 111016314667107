import React, { createContext, useCallback, useEffect, useReducer } from 'react';

import { BASE_URL, LOCAL_STORAGE, SESSION_STORAGE } from '../config';
import { useGetUserAccount } from '../hooks/useQueries';
import { salesforceActions } from '../redux/slices/salesforce';
import { useDispatch } from '../redux/store';
import { accessTokenSet, setRootLogout } from '../utils/apiQuery';
import { ActionMapType, AuthRoleType, AuthStateType, AuthUserType, ContextType } from './types';

export enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    role: null;
    clientName: '';
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    role: AuthRoleType;
    clientName: string;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    role: AuthRoleType;
    clientName: string;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  role: null,
  clientName: '',
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      role: action.payload.role,
      clientName: action.payload.clientName,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      role: action.payload.role,
      clientName: action.payload.clientName,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      role: action.payload.role,
      clientName: action.payload.clientName,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      role: null,
      clientName: '',
    };
  }

  return state;
};

export const AuthContext = createContext<ContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const reduxDispatch = useDispatch();

  const { data: userAccount, refetch, error, isLoading } = useGetUserAccount(accessTokenSet());

  useEffect(() => {
    // Try to get user data
    if (!userAccount || error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: accessTokenSet(),
          user: null,
          role: null,
          clientName: '',
        },
      });
    } else {
      const { user, role = null, clientName = '', salesforceUrl } = userAccount;
      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
          role,
          clientName,
        },
      });
      if (salesforceUrl?.url) {
        reduxDispatch(salesforceActions.saveUserSalesforceUrl(salesforceUrl.url));
      }
    }
  }, [userAccount, error, reduxDispatch]);

  // LOGIN WITH USERNAME AND PASSWORD

  // LOGIN
  const loginWithMS = async () => {
    window.location.href = BASE_URL + '/auth/azure';
  };

  const loginWithGoogle = async () => {
    window.location.href = BASE_URL + '/auth/google';
  };

  // LOGOUT
  const logout = async () => {
    dispatch({
      type: Types.LOGOUT,
    });
    localStorage.removeItem(LOCAL_STORAGE.SALESFORCE_TOKEN);
    // localStorage.removeItem(LOCAL_STORAGE.DEALS_FILTERS);
    // localStorage.removeItem(LOCAL_STORAGE.GROUPING);
    sessionStorage.removeItem(SESSION_STORAGE.SELECTED_SECTION);
    reduxDispatch(salesforceActions.deleteUserSalesforceUrl());
    // This will revoke the access token
    await fetch(`${BASE_URL}/auth/jwt/logout`, {
      credentials: 'include',
      method: 'POST',
    });
  };
  // This gets set on initial load to logout users if they get a 401
  setRootLogout(logout);
  // TODO: Remove this once all clients are on the latest version.
  if (window.localStorage.getItem('accessToken') || window.localStorage.getItem('refreshToken')) {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    logout().finally();
  }
  const auth = useCallback(async () => {
    await refetch();
  }, [refetch]);

  // update url on refresh page
  useEffect(() => {
    if (!userAccount) return;
    userAccount.salesforceUrl?.url &&
      reduxDispatch(salesforceActions.saveUserSalesforceUrl(userAccount.salesforceUrl.url));
  }, [userAccount, reduxDispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'all',
        loginWithMS,
        loginWithGoogle,
        logout,
        auth,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
